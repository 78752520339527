import React from 'react'
import styled from '@emotion/styled'

import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Button from 'src/components/Button'
import ClippedImage from 'src/components/ClippedImage'

import { colors } from 'src/styles'

const Wrapper = styled(Section)``

const ColumnWrapper = styled.div`
	h1,
	h2,
	h3 {
		max-width: 20em;
	}
	h4,
	h5 {
		max-width: 26em;
	}
	h5 {
		margin-top: 0;
	}
	h6 {
		max-width: 16em;
	}
	p {
		max-width: 40em;
	}
	ul, ol {
		li {
			line-height: 2em;
			p {
				margin: 0;
			}
		}
	}
`

const IngredientsText = styled(TextLockup)`
	ul, ol {
		margin: 0;
		padding-left: 20px;
		li::marker {
			color: ${ colors.mainColor };
		}
	}
`

const MethodText = styled(TextLockup)`
	ul, ol {
		margin: 0;
		padding-left: 20px;
		li::marker {
			font-weight: 600;
			color: ${ colors.mainColor };
		}
	}
`

const CocktailImage = styled(ClippedImage)`
	padding-top: 95%;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
		> div {
			max-width: 100% !important;
		}
	}
`

const BuyNowButton = styled(Button)`
`

const CocktailHero = ({ className, cocktail }) => {
	return (
		<Wrapper className={className} isFirstSection={true} nextTheme='midPink'>
			<Grid
				small="container"
				large="1 [6] 1 [5] 1"
				larger="2 [11] 2 [11] 2"
				extraLarge="4 [9] 2 [10] 3"
				vAlign="top"
				rowGap={['4vw', '5vw', '80px']}
			>
				<ScrollEntrance delay={0 /* TBD */}>
					<CocktailImage
						image={cocktail.image}
						clipPathId="cocktailHero"
						aspectRatio={1}
					/>
				</ScrollEntrance>

				<ColumnWrapper>
					<Grid small='[1]' medium='[1]' larger='[1]' rowGap={['20px', '30px', '50px']}>
						<TextLockup
							eyebrow={cocktail.title}
							text={cocktail.description}
							textSize='bodySmall'
							headlineStyle='handwritten'
							handwrittenEyebrow={true}
						/>

						<IngredientsText
							entranceDelay={3}
							eyebrow="Ingredients"
							eyebrowUnderline={false}
							text={cocktail.ingredients}
							textSize="bodySmall"
						/>
						<MethodText
							entranceDelay={5}
							eyebrow="Method"
							eyebrowUnderline={false}
							text={cocktail.method}
							textSize="bodySmall"
						/>

						<ScrollEntrance delay={6}>
							<div>
								<BuyNowButton
									setTheme="default"
									to={'/shop/flora-rosa/'}
									external={false}
									title="Buy Now"
									name="Buy Now"
								>
									Buy Now
								</BuyNowButton>
							</div>
						</ScrollEntrance>
					</Grid>
				</ColumnWrapper>
			</Grid>
		</Wrapper>
	)
}

export default CocktailHero
