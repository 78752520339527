import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import Header from 'src/components/Header'
import WideMedia from 'src/components/WideMedia'
import CocktailHero from 'src/components/CocktailHero'
import MoreCocktails from 'src/components/MoreCocktails'
import Footer from 'src/components/Footer'

const propTypes = {
	data: PropTypes.object.isRequired,
}

class CocktailTemplate extends React.Component {
	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const allCocktails = this.props.data.allContentfulCocktail.edges
		const cocktailPathTrim = this.props.path.substring(this.props.path.lastIndexOf('/') + 1)
		let cocktail = allCocktails[0].node

		if (allCocktails.find(element => element.node.slug === cocktailPathTrim)) {
			cocktail = allCocktails.find(element => element.node.slug === cocktailPathTrim).node
		}

		const moreCocktails = allCocktails.filter(item => item.node.slug !== cocktailPathTrim)

		const seo = cocktail.seo

		return (
			<Fragment>
				<SEO
					title={cocktail.title}
					description={seo?.description?.description}
					siteSettings={site}
					keywords={seo?.keywords}
					shareImage={seo?.shareImage && 'https:' + seo.shareImage.file.url}
				/>
				<Header
					hasAtf={false}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          rightNavigation={site.rightNavigation}
          location={cocktail.slug}
          title={site.title}
          cartLink={site.cartLink}
				/>

				<CocktailHero
					cocktail={cocktail}
				/>

				<MoreCocktails
					cocktails={moreCocktails}
				/>

				<WideMedia
					prevTheme='midPink'
					{...site.softFooter}
				/>

				<Footer {...site} />
			</Fragment>
		)
	}
}

CocktailTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulCocktail(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
			edges {
				node {
					...Cocktail
				}
			}
    }
	}
`

export default CocktailTemplate
